import React, { useState } from 'react';
import axios from 'axios';
import { Skeleton,Image } from 'antd';
import Teamspost from "./Teamspost";
import ReactMarkdown from 'react-markdown';

const ImageUploader = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [result, setResult] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadedUrl, setUploadedUrl] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setResult('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedFile) {
            alert('写真を選んでください');
            return;
        }
        
        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64Image = reader.result.split(',')[1];
            const imageDataUrl = `data:image/jpeg;base64,${base64Image}`;

            const url = 'http://ec2-184-73-12-167.compute-1.amazonaws.com:8025/multimodal/chat';
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 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',
            };

            const data = {
                "model": "gpt-4o-2024-11-20",
                "messages": [
                    {
                        "role": "user",
                        "content": [
                            { "type": "image_url", "image_url": { "url": imageDataUrl } },
                            { "type": "text", "text": "あなたは労働災害の監査を行う監督官です。この工事現場の写真の中で法令違反となる可能性があるポイントを列挙してください。特に赤い枠のところ。危険度が高いのところを優先して、人命に直結する部分を強調してください。 " }
                        ]
                    }
                ],
                "max_tokens": 3000,
                "temperature": 0
            };

            try {
                setLoading(true);
                const response = await axios.post(url, data, { headers });
                // setResult(response.data.choices[0].message.content);
                setResult(response.data.rag_decorated);
            } catch (error) {
                console.error('未知エラー', error);
                setResult('未知エラー');
            } finally {
                setLoading(false);
            }

            const formData = new FormData();
            formData.append('key', '42af831a9821afc0f17e2985eb1cf0a9');
            formData.append('image', selectedFile);

            try {
                const response = await axios({
                    method: 'post',
                    url: 'https://api.imgbb.com/1/upload',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const data = response.data;
                if (response.status === 200) {
                    setUploadedUrl(data.data.url);
                    // alert('Image uploaded successfully!');
                } else {
                    throw new Error(data.error.message);
                }
            } catch (err) {
                console.error(err);
                alert('Failed to upload image');
            }

        };

        reader.readAsDataURL(selectedFile); // base64
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input type="file" accept="image/*" onChange={handleFileChange} />
                <button type="submit" disabled={loading}>AI解析</button>
            </form>
            {result && (
                <div>
                    {/*<a href={uploadedUrl} target="_blank" rel="noopener noreferrer">画像のプレビュー</a>*/}
                    <h2>解析結果</h2>
                    <Image
                        width={300}
                        src={uploadedUrl}
                    />
                    <ReactMarkdown>{result}</ReactMarkdown>
                    <Teamspost resultData={result} resultUrl={uploadedUrl}/>
                    {/*<img src={uploadedUrl} alt="Uploaded"/>*/}
                </div>
            )}
            {loading && <div><p>読み込んでいます...</p><Skeleton active/></div>}
        </div>
    );
};

export default ImageUploader;
